
import SignaturePad from 'signature_pad';

export default {
  name: `ESignature`,
  props: {
    height: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      signaturePad: null,
      redoList: [],
      drawing: false,
      currentStrokeBackup: null,
    };
  },
  mounted() {
    this.clear();
    this.$nextTick(() => {
      this.resizeCanvas();
      window.addEventListener(`resize`, this.resizeCanvas);
      window.addEventListener(`orientationchange`, this.resizeCanvas);
      // Also try a delayed resize to catch any late layout adjustments
      setTimeout(this.resizeCanvas, 200);
    });
  },
  methods: {
    resizeCanvas() {
      this.$nextTick(() => {
        const canvas = this.$refs.signatureCanvas;
        if (!canvas) return;
        const computedStyle = getComputedStyle(canvas);
        const width = parseInt(computedStyle.getPropertyValue(`width`), 10);
        const height = this.height;
        const ratio = window.devicePixelRatio || 1;
        canvas.width = width * ratio;
        canvas.height = height * ratio;
        canvas.getContext(`2d`).scale(ratio, ratio);
      });
    },
    handlePointerLeave() {
      this.clear()
    },
    redo() {
      const data = this.signaturePad.toData();
      if (this.redoList.length > 0) {
        this.signaturePad.fromData(data.concat(this.redoList.pop()));
      }
    },
    undo() {
      const data = this.signaturePad.toData();
      if (data && data.length > 0) {
        this.redoList.push(data.pop());
        this.signaturePad.fromData(data);
      }
    },
    clearRedo() {
      this.redoList = [];
    },
    handleGlobalPointerMove(event) {
      const canvas = this.$refs.signatureCanvas;
      if (!canvas) {
        return;
      }

      const rect = canvas.getBoundingClientRect();
      if (!this.drawing) {
        return;
      }
      if (
        event.clientX < rect.left ||
        event.clientX > rect.right ||
        event.clientY < rect.top ||
        event.clientY > rect.bottom
      ) {
        this.undo();
      }
    },
    clear() {
      const canvas = this.$refs.signatureCanvas;
      if (this.signaturePad) {
        this.signaturePad.clear();
      } else {
        this.signaturePad = new SignaturePad(canvas);

        this.signaturePad.addEventListener(`endStroke`, this.clearRedo);

        document.addEventListener(`pointermove`, this.handleGlobalPointerMove.bind(this));
        canvas.addEventListener(`pointerdown`, this.handlePointerDown);
        canvas.addEventListener(`pointerup`, this.handlePointerUp);
      }
    },
    generate() {
      if (this.signaturePad && !this.signaturePad.isEmpty()) {
        return this.signaturePad.toDataURL(`image/png`);
      }
      return null;
    },
    loadSignature(data) {
      this.signaturePad.fromDataURL(data);
    },
    getData() {
      return this.signaturePad.toData()
    },
    getDimensions () {
      return {
        width: this.$refs.signatureCanvas.width,
        height: this.$refs.signatureCanvas.height
      };
    },
    fromData(data) {
      const canvas = this.$refs.signatureCanvas;
      const width = canvas.width;
      const height = canvas.height;
      const boundingBox = this.calcBoundingBox(data);
      // move to center of canvas
      const offsetX = (width - (boundingBox.maxX - boundingBox.minX)) / 2 - boundingBox.minX;
      const offsetY = (height - (boundingBox.maxY - boundingBox.minY)) / 2 - boundingBox.minY;
      data.forEach(stroke => {
        stroke.points = stroke.points.map(point => ({
          x: point.x + offsetX,
          y: point.y + offsetY,
          time: point.time,
        }));
      });

      this.signaturePad.fromData(data);
    },
    handlePointerDown() {
      this.drawing = true;
    },
    handlePointerUp() {
      this.drawing = false;
    },
    isEmpty() {
      return this.signaturePad.isEmpty();
    },
    isTooSmall() {
      const bbox = this.calcBoundingBox(this.signaturePad.toData());

      const biggest = Math.max(bbox.maxX - bbox.minX, bbox.maxY - bbox.minY);

      const canvas = this.$refs.signatureCanvas;
      const rect = canvas.getBoundingClientRect();

      const smallestSide = Math.min(rect.width, rect.height);
      
      if (biggest > smallestSide / 2) {
        return false;
      }
      return true;
    },
    calcBoundingBox(data) {

      const points = data.reduce((acc, stroke) => {
        return acc.concat(stroke.points);
      }, []);

      const minX = Math.min(...points.map(point => point.x));
      const minY = Math.min(...points.map(point => point.y));
      const maxX = Math.max(...points.map(point => point.x));
      const maxY = Math.max(...points.map(point => point.y));

      return {
        minX,
        minY,
        maxX,
        maxY,
      };
      
    },
    rotate() {
      const canvas = this.$refs.signatureCanvas;
      const width = canvas.width;
      const height = canvas.height;
      
      // Get the current drawing strokes.
      const strokes = this.signaturePad.toData();
      const boundingBox = this.calcBoundingBox(strokes);

      const centerX = (boundingBox.minX + boundingBox.maxX) / 2;
      const centerY = (boundingBox.minY + boundingBox.maxY) / 2;

      let scale = 1;
      if (boundingBox.maxX - boundingBox.minX > height || boundingBox.maxY - boundingBox.minY > width) {
        scale = Math.min(height / (boundingBox.maxX - boundingBox.minX), width / (boundingBox.maxY - boundingBox.minY));
        scale *= 0.9;
        
      }

      const rotatedStrokes = strokes.map(stroke => {
          const points = stroke.points.map(point => ({
            ...point,
            x: (-(point.y - centerY) * scale) + width / 2,
            y: ((point.x - centerX) * scale) + height / 2,
          }))
          stroke.points = points;
          return stroke;
        }
      );
      this.signaturePad.fromData(rotatedStrokes);
    },
  }
};
