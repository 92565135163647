
import VueQr from 'vue-qr';

export default {
  name: `SignViaMobile`,
  components: {
    VueQr,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: ``,
    },
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.cancel();
      },
    },
    qrCodeText() {
      const baseUrl = this.$config.public.baseUrl;
      return `${baseUrl}/mobile_signer/?token=${this.token}`;
    },
  },
  methods: {
    cancel() {
      this.$emit(`close`);
    },
  },
};
